<template>
  <div class="locator-intro__container container flex justify-center items-center relative overflow-hidden p-4 ">
    <LocatorIntro />
  </div>
</template>

<script>

import LocatorIntro from '@/components/LocatorIndex/LocatorIntro.vue';

export default {
  name: 'SiteIndex',
  components: { LocatorIntro },
  props: {},
};
</script>

<style scoped>
  .locator-intro__container{
    max-width: none !important;
  }
</style>
