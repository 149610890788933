<template>
  <div class="container p-4 locator-intro">
    <card class="border-2 border-gray-50 rounded-md">
      <sh1 class="mb-8"> Find a Paint<br />Drop-off Site </sh1>
      <sp class="mb-12 text-black">
        Choose your location to find the nearest paint drop-off site.
      </sp>
      <sgeocoder :intro="true" type="home" />
    </card>
  </div>
</template>

<script>
import sh1 from '@/components/atoms/s-h1.vue';
import sp from '@/components/atoms/s-p.vue';
import card from '@/components/atoms/card.vue';
import sgeocoder from '@/components/atoms/s-geocoder.vue';
import { useGeocoding } from '@/common/composition/map';

export default {
  name: 'IndexIntro',
  components: {
    sh1,
    sp,
    card,
    sgeocoder,
  },
  setup() {
    const {
      setState,
      selectedState,
    } = useGeocoding('home');

    return {
      setState,
      selectedState,
    };
  },
};
</script>

<style scoped>

  .locator-intro{
    max-width: 1500px;
  }

</style>
