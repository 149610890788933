<template>
  <h1 class="text-32 text-black font-extrabold md:text-64">
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'SH1',
};
</script>
